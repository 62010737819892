<template>
  <v-container>
    <v-row>
      <v-col
        md="8"
        offset-md="2"
      >
        <v-alert
          icon="mdi-magnify"
          prominent
          text
          type="info"
          color="primary"
        >
          Please review the program settings before starting.
          You can go back and change information if something is out of order
        </v-alert>
      </v-col>
    </v-row>

    <v-divider class="mb-2" />

    <v-row>
      <v-col
        md="2"
        offset-md="1"
      >
        <div class="title">
          Participants
        </div>
      </v-col>
      <v-col
        md="6"
      >
        <div class="grey--text">
          When the program is started participants will be notified.
          If a participant is anonymous you will need to invite them with a code you will receive
          when the program has started
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="6"
        offset-md="3"
      >
        <v-card>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                  Role
                </th>
                <th class="text-left">
                  Participant
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Subject</td>
                <td>{{ roles.subject | formatRole }}</td>
              </tr>
              <tr>
                <td>Supporter</td>
                <td>{{ roles.supporter | formatRole }}</td>
              </tr>
              <tr>
                <td>Observer</td>
                <td>{{ roles.observer | formatRole }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <v-alert
          v-if="allRolesAnonymous === true"
          type="warning"
          class="mt-4"
        >
          All roles are anonymous.
          Remember to assign roles using invitation codes after you started the program
        </v-alert>
      </v-col>
    </v-row>

    <v-divider class="mb-2" />

    <v-row>
      <v-col
        md="2"
        offset-md="1"
      >
        <div class="title">
          Schedule
        </div>
      </v-col>
      <v-col
        md="6"
      >
        <div class="grey--text">
          Overview of all the scheduled event defined in the program
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="8"
        offset-md="3"
      >
        <v-card>
          <v-container
            id="scroll-target"
            style="max-height: 400px"
            class="overflow-y-auto"
          >
            <v-timeline>
              <v-timeline-item
                v-for="(event, index) in schedule"
                :key="index"
                icon="mdi-chat-alert"
                large
                right
              >
                <template #opposite>
                  <span>{{ event.eventAt | formatDateDetail }}</span>
                </template>
                <v-card class="elevation-2">
                  <v-card-text>
                    <v-card
                      v-if="event.subjectSendReminder || event.supporterSendReminder"
                      class="pa-2 mb-4"
                      color="deep-purple lighten-5"
                    >
                      <div class="subtitle-2 text-center">
                        Reminder
                      </div>
                      <div
                        v-if="event.subjectSendReminder"
                        class="overline"
                      >
                        <strong>Subject</strong>
                        {{ event.subjectReminderBeforeMinutes }}
                        min before
                      </div>

                      <div
                        v-if="event.supporterSendReminder"
                        class="overline"
                      >
                        <strong>Supported</strong>
                        {{ event.supporterReminderBeforeMinutes }}
                        min before
                      </div>
                    </v-card>


                    <v-card
                      class="pa-2"
                      color="cyan lighten-5"
                    >
                      <div class="subtitle-2 text-center">
                        Feedback
                      </div>
                      <div class="overline">
                        <strong>Subject</strong>
                        {{ event.subjectFeedbackAfterMinutes }}
                        min after
                      </div>
                      <div class="overline">
                        <strong>Supporter</strong>
                        {{ event.supporterFeedbackAfterMinutes }}
                        min after
                      </div>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-container>
        </v-card>
        <div class="subtitle-2 text-center mt-2">
          Total of {{ schedule.length }} events
        </div>
      </v-col>
    </v-row>

    <v-divider class="mb-2" />

    <v-row>
      <v-col
        md="2"
        offset-md="1"
      >
        <div class="title">
          Rewards
        </div>
      </v-col>
      <v-col
        md="6"
      >
        <div class="grey--text">
          Overview of all the rewards
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="8"
        offset-md="3"
      >
        <v-card
          v-if="rewards.length > 0"
          class="mt-4"
        >
          <v-container
            id="scroll-target"
            style="max-height: 400px"
            class="overflow-y-auto"
          >
            <v-card
              class="d-flex flex-wrap"
              flat
              tile
            >
              <RewardSquare
                v-for="reward in rewards"
                :key="reward.id"
                :reward="reward"
                :title="reward.title"
                :max-width="150"
                :height="150"
              />
            </v-card>
          </v-container>
        </v-card>

        <v-alert
          v-if="rewards.length == 0"
          type="warning"
        >
          Having no rewards is technically allowed but not recommended
        </v-alert>

        <div class="subtitle-2 text-center mt-2">
          Total of {{ rewards.length }} rewards
        </div>
      </v-col>
    </v-row>

    <v-divider class="mb-2" />

    <v-row>
      <v-col
        md="2"
        offset-md="1"
      >
        <div class="title">
          Achievements
        </div>
      </v-col>
      <v-col
        md="6"
      >
        <div class="grey--text">
          Overview of all the achievements
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="8"
        offset-md="3"
      >
        <v-card
          v-if="achievements.length > 0"
          class="mt-4"
        >
          <v-container
            id="scroll-target"
            style="max-height: 400px"
            class="overflow-y-auto"
          >
            <v-card
              class="d-flex flex-wrap"
              flat
              tile
            >
              <AchievementSquare
                v-for="achievement in achievements"
                :key="achievement.id"
                :achievement="achievement"
                :title="achievement.title"
                :max-width="150"
                :height="150"
              />
            </v-card>
          </v-container>
        </v-card>

        <v-alert
          v-if="achievements.length == 0"
          type="warning"
        >
          Having no achievements is technically allowed but not recommended
        </v-alert>

        <div class="subtitle-2 text-center mt-2">
          Total of {{ achievements.length }} achievements
        </div>
      </v-col>
    </v-row>

    <v-divider class="mb-2" />

    <v-row>
      <v-col
        md="2"
        offset-md="1"
      >
        <div class="title">
          Settings
        </div>
      </v-col>
      <v-col
        md="6"
      >
        <div class="grey--text">
          Current program settings.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="6"
        offset-md="3"
      >
        <v-alert type="info">
          Auto feedback from supporter is enabled.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="6"
        offset-md="3"
      >
        <v-card>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Setting
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Owner Report Email</td>
                <td>
                  <span v-if="settings.report.email != ''">{{ settings.report.email }}</span>
                  <span
                    v-else
                    class="font-italic grey--text"
                  >Blank</span>
                </td>
              </tr>
              <tr>
                <td>Chat</td>
                <td>
                  <span
                    v-if="settings.chat.enabled"
                    class="green--text"
                  >Enabled</span>
                  <span
                    v-else
                    class="red--text"
                  >Disabled</span>
                </td>
              </tr>
              <tr>
                <td>Chat Banner</td>
                <td>
                  <span v-if="settings.chat.banner != ''">{{ settings.chat.banner }}</span>
                  <span
                    v-else
                    class="font-italic grey--text"
                  >Blank</span>
                </td>
              </tr>
              <tr>
                <td>Chat Link</td>
                <td>
                  <span v-if="settings.chat.link != ''">{{ settings.chat.link }}</span>
                  <span
                    v-else
                    class="font-italic grey--text"
                  >Blank</span>
                </td>
              </tr>
              <tr>
                <td>Store</td>
                <td>
                  <span
                    v-if="settings.store.enabled"
                    class="green--text"
                  >Enabled</span>
                  <span
                    v-else
                    class="red--text"
                  >Disabled</span>
                </td>
              </tr>
              <tr>
                <td>Auto Supporter Feedback</td>
                <td>
                  <span
                    v-if="settings.feedback.supporterAuto"
                    class="green--text"
                  >Enabled</span>
                  <span
                    v-else
                    class="red--text"
                  >Disabled</span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <br>
      </v-col>
    </v-row>
    <v-divider />
  </v-container>
</template>


<script>

import RewardSquare from '@/components/programs/start/RewardSquare.vue';
import AchievementSquare from '@/components/programs/start/AchievementSquare.vue';

export default {
  name: 'ProgramOverview',
  components: {
    RewardSquare,
    AchievementSquare,
  },
  data() {
    return {

    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    roles() {
      return this.$store.getters.roles;
    },
    schedule() {
      return this.$store.getters.schedule;
    },
    allRolesAnonymous() {
      const subjectAnonymous = this.roles.subject.me === false && this.roles.subject.email === '';
      const supporterAnonymous = this.roles.supporter.me === false && this.roles.supporter.email === '';
      const observerAnonymous = this.roles.observer.me === false && this.roles.observer.email === '';
      return subjectAnonymous && supporterAnonymous && observerAnonymous;
    },
    rewards() {
      return this.$store.getters.rewards;
    },
    achievements() {
      return this.$store.getters.achievements;
    },
    settings() {
      return this.$store.getters.settings;
    },
    result() {
      return this.$store.getters.result;
    },

  },

};
</script>
