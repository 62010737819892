<template>
  <v-container class="ma-0 fill-height">
    <v-card
      height="100%"
      width="100%"
    >
      <v-tabs>
        <v-tab>
          <v-icon left>
            mdi-run-fast
          </v-icon>View Programs
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-clock-start
          </v-icon>Start a Program
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-chart-line
          </v-icon>Create a Report
        </v-tab>

        <v-tab-item class="pa-4">
          <v-row class="mb-2 mx-2">
            <span class="headline grey--text">
              Select a program
            </span>
            <v-spacer />
            <v-switch
              v-model="showFinished"
              label="Show Finished Programs"
            />
          </v-row>
          <ProgramRow
            v-for="program in programs"
            :key="program.id"
            :program="program"
            @click="editProgram(program.id)"
          />

          <v-card
            v-if="programs.length == 0"
            class="pa-4"
            max-width="400"
          >
            You do not have any running programs. Start one first.
          </v-card>
        </v-tab-item>

        <v-tab-item class="pa-4">
          <v-row class="mb-2 mx-2">
            <span class="headline grey--text">
              Select a template
            </span>
            <v-spacer />
            <v-switch
              v-model="showPublic"
              label="Show Public Templates"
            />
          </v-row>
          <TemplateRow
            v-for="template in templates"
            :key="template.id"
            :template="template"
            @click="startProgram(template)"
          />
          <v-btn
            v-if="isMore"
            block
            @click="page += 1"
          >
            Next
          </v-btn>
        </v-tab-item>

        <v-tab-item class="pa-4">
          <v-row class="mb-2 mx-2">
            <span class="headline grey--text">
              Create a Report
            </span>
            <v-spacer />
          </v-row>
          <Report />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>


<script>
import firebase from 'firebase/app';
import { db } from '@/firebase';

import TemplateRow from '@/components/templates/TemplateRow.vue';
import ProgramRow from '@/components/programs/ProgramRow.vue';
import Report from '@/components/programs/Report.vue';

const pageSize = 20;

export default {
  name: 'ProgramsList',
  components: {
    TemplateRow,
    ProgramRow,
    Report,
  },
  data() {
    return {
      templates: [],
      programs: [],
      showPublic: this.$store.getters.showPublicPrograms,
      showFinished: this.$store.getters.showFinishedPrograms,     
      // Pagination is only for templates at the moment
      page: 1,
      isMore: true,
      lastVisible: null,
    };
  },

  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  watch: {
    //
    // Programs
    //
    showFinished: {
      immediate: true,
      handler(val, oldVal) {
        const programs = db.collection('programs');
        if (val === false) {
          this.$bind('programs', programs.where('ownerId', '==', this.user.uid).where('running', '==', true).orderBy('createdAt', 'desc'), { maxRefDepth: 0 });
        } else if (val === true) {
          this.$bind('programs', programs.where('ownerId', '==', this.user.uid).where('running', '==', false).orderBy('createdAt', 'desc'), { maxRefDepth: 0 });
        }
        this.$store.commit('setShowFinishedPrograms', val);
      },
    },

    //
    // Program templates (with pagination)
    //
    showPublic: {
      immediate: true,
      handler(val, oldVal) {
        // Reset pagination
        this.page = 1;
        this.isMore = true;
        this.lastVisible = null;

        const templates = db.collection('templates');
        if (val === false) {
          this.$bind('templates', templates.where('ownerId', '==', this.user.uid).limit(pageSize));
        } else if (val === true) {
          this.$bind('templates', templates.where('public', '==', true).limit(pageSize));
        }
        this.$store.commit('setShowPublicPrograms', val);
      },
    },

    page: {
      handler(val, oldVal) {

        if (this.lastVisible === null) return; // Not ready

        const templates = db.collection('templates');
        if (this.showPublic === false) {
          this.$bind('templates', templates.where('ownerId', '==', this.user.uid).startAfter(this.lastVisible).limit(pageSize));
        } else if (this.showPublic === true) {
          this.$bind('templates', templates.where('public', '==', true).startAfter(this.lastVisible).limit(pageSize));
        }
      },
    },

    templates: {
      handler(val, oldVal) {
        const newTemplates = val;

        if (newTemplates.length === 0) return; // Ignore this

        if (newTemplates.length === pageSize) {
          this.isMore = true;
        } else if (newTemplates.length < pageSize) {
          this.isMore = false;
        } 

        const lastVisibleId = newTemplates[newTemplates.length - 1].id;

        db.collection('templates')
          .doc(lastVisibleId)
          .get()
          .then((snapshot) => {
            this.lastVisible = snapshot;
          });

      },
    },
  },

  methods: {
    startProgram(template) {
      if (template.image === null) {
        alert('You need to add an image to this template');
        return;
      }
      this.$router.push(`/programs/start/${template.id}`);
    },

    editProgram(programId) {
      this.$router.push(`/programs/${programId}`);
    },
  },

};
</script>

